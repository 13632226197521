<template>
  <div>
    <div class="sectionButtons-actions">
      <CButton
        color="success"
        size="lg"
        class="sectionButtons-actionsItem"
        @click="openCardNewUser()"
        >{{ $t('new') }}  {{ $t('user') }}</CButton
      >
    </div>
    <CCard accent-color="primary">
      <CCardHeader color="white">
        <b class="text-dark">{{ tableTitle }}</b>
      </CCardHeader>
      <CCardBody>
        <!-- section search -->
        <BlockColumn>
          <BlockItem :size="'1-2'">
            <BlockInfo :title="$t('plant_label')" @input-change="changePlant" />
          </BlockItem>
          <BlockItem :size="'1-2'">
            <BlockInfoSelect
            :title="$t('country_label')"
              :selected="countryCodes[0]"
              :values="countryCodes"
              @change-value="changeCountry"
            />
          </BlockItem>
        </BlockColumn>

        <CDataTable
          class="mb-0 table-outline"
          hover
          :items="tableItems"
          :fields="tableFields"
          head-color="light"
          no-sorting
          :items-per-page="10"
          pagination
          :table-filter="{label: $t('table_filter')}"
          :items-per-page-select="{label: $t('items_page')}"
        >
          <template #plant="{ item }">
            <td>
              <div class="cbadge-group">
                <CBadge
                  v-for="_plant in item.plant"
                  :key="_plant"
                  color="primary"
                  shape="pill"
                  class="cbadge-item"
                  >{{ _plant }}</CBadge
                >
              </div>
            </td>
          </template>

          <template #options="{ item }">
            <td class="text-right">
              <CButton size="sm" class="" @click="openCardUserEdit(item)">
                <CIcon name="cil-pencil" />
              </CButton>
              <CButton size="sm" class="" @click="openCardUserDelete(item)">
                <CIcon name="cil-trash" />
              </CButton>
            </td>
          </template>
          <template #no-items-view>
            <div class="d-flex align-items-center justify-content-center my-3">
                <span class="mr-2" style="font-size: 30px; font-weight: 600; ">{{ $t('no_items') }}</span>
                <CIcon name="cil-ban" class="text-danger" style="width: 35px; height: 35px;" />
              </div>
          </template>
        </CDataTable>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import BlockColumn from "./BlockColumn";
import BlockItem from "./BlockItem";
import BlockInfoSelect from "./BlockInfoSelect";
import BlockInfo from "./BlockInfo";
export default {
  name: "ListUser",
  components: {
    BlockColumn,
    BlockItem,
    BlockInfoSelect,
    BlockInfo,
  },
  props: {
    tableItems: Array,
    countryCodes: Array,
  },
  data() {
    return {
      layout: "list",

      // props table Users
      tableTitle: this.$i18n.t("console_user"),
      tableFields: [
        { key: "email", label: this.$t('email'), _classes: "text-left" },
        { key: "rol", label: this.$t('rol'), _classes: "text-left" },
        { key: "username",label: this.$t('username'), _classes: "text-center" },
        { key: "country",label: this.$t('country'), _classes: "text-center" },
        { key: "plant", label: this.$t('plant'),_classes: "text-center" },
        { key: "options",label: this.$t('options'), _classes: "text-center" },
      ],
    };
  },
  methods: {
    openCardUserEdit(user) {
      this.$emit("user-edit", {
        showCardUser: true,
        userSelected: user,
        inputsDisabled: false,
        countryCodes: this.countryCodes,
        btnEventText: "Save",
        btnEventType: "success",
        cardTitle: "Edit user",
      });
    },
    openCardUserDelete(user) {
      this.$emit("user-delete", {
        showCardUser: true,
        userSelected: user,
        inputsDisabled: true,
        notificationType: "danger",
        notification: `You are about to eliminate the user with email ${user.email}`,

        btnEventText: "Delete",
        btnEventType: "danger",
        cardTitle: "Delete user",
      });
    },
    openCardNewUser() {
      this.$emit("user-new", {
        showCardUser: true,
        userSelected: {
          country: "",
          email: "",
          plant: "",
          rol: "",
          username: "",
        },
        inputsDisabled: false,
        countryCodes: this.countryCodes,
        btnEventText: "New",
        btnEventType: "success",
        cardTitle: "New user",
      });
    },

    changePlant(plant) {
      this.$emit("driver-filter", { key: "PLANT", plant });
    },
    changeCountry(country) {
      this.$emit("driver-filter", { key: "COUNTRY", country });
    },
  },
};
</script>

<style lang="scss" scoped>
.sectionButtons-actions {
  padding: 1em 0 2em 1em;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.sectionButtons-actionsItem {
  margin-left: 1em;
  font-size: 14px;
}

.cbadge-group {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.cbadge-item {
  font-size: 10px;
  margin: 0 2px;
}
</style>